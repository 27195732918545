textarea {
  resize: none;
}

/* hover animate  start*/

.position-aware {
  position: relative;
  overflow: hidden;
}

.position-aware span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05);
  transition: width 0.25s ease-in-out, height 0.25s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
}

.position-aware:hover span {
  width: 250%;
  height: 300%;
}

/* hover animate end*/

/* public start*/

::placeholder {
  color: #949494;
}

.no-shadow {
  box-shadow: none !important;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 1 !important;
  visibility: visible;
}

.opacity-2 {
  opacity: 0.2 !important;
  visibility: visible;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.no-outline {
  overflow: hidden !important;
}

.pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: unset !important;
}

.line-height-unset {
  line-height: unset !important;
}

.l-h-36px {
  line-height: 36px !important;
}

.l-h-19px {
  line-height: 19px !important;
}

.l-h-18px {
  line-height: 18px !important;
}

.l-h-17px {
  line-height: 17px !important;
}

.l-h-13px {
  line-height: 13px !important;
}

.l-h-12px {
  line-height: 12px !important;
}

.l-h-10px {
  line-height: 10px !important;
}

.h-0 {
  height: 0px !important;
}

.h-100 {
  height: 100% !important;
}

.h-100-vh {
  height: 100vh !important;
  height: 100dvh !important;
}

.max-h-100-vh {
  max-height: 100vh !important;
  max-height: 100dvh !important;
}

.w-0 {
  width: 0 !important;
}

.w-max-content {
  width: max-content !important;
}

.h-max-content {
  height: max-content !important;
}

.w-80px {
  width: 80px !important;
}

.w-100px {
  width: 100px !important;
}

.w-120px {
  width: 120px !important;
}

.w-122px {
  width: 122px !important;
}

.w-150px {
  width: 150px !important;
}

.w-170px {
  width: 170px !important;
}

.w-100-vw {
  width: 100vw;
}

.w-95 {
  width: 95% !important;
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.w-60 {
  width: 60% !important;
}

.w-49 {
  width: 49% !important;
}

.w-40 {
  width: 40% !important;
}

.w-100px {
  width: 100px !important;
}

.w-20 {
  width: 20% !important;
}

.w-300px {
  width: 300px !important;
}

.w-320px {
  width: 320px !important;
}

.w-max-400px {
  max-width: 400px !important;
}

.w-max-300px {
  max-width: 300px !important;
}

.m-w-90 {
  max-width: 90% !important;
}

.fit-height {
  height: fit-content;
  height: -moz-fit-content;
}

.fit-width {
  width: fit-content;
  width: -moz-fit-content;
}

.max-h-100 {
  max-height: 100% !important;
}

.hidden {
  display: none !important;
}

.direction-rtl {
  direction: rtl !important;
}

.direction-ltr {
  direction: ltr !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-unset {
  overflow: unset !important;
}

.dont-show-more-line {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis !important;
}

.no-radius {
  border-radius: 0 !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-top-dotted {
  border-top-style: dotted !important;
}

.border-1px {
  border: 1px solid;
}

.border-top-1px {
  border-top: 1px solid;
}

.border-bottom-1px {
  border-bottom: 1px solid;
}
.border-2px {
  border: 2px solid;
}

.border-top-2px {
  border-top-width: 2px;
}

.border-left-6px {
  border-left: 6px solid !important;
}

.border-3px {
  border: 3px solid;
}

.radius-none {
  border-radius: 0 !important;
}

.radius-2px {
  border-radius: 2px !important;
}

.radius-4px {
  border-radius: 4px !important;
}

.radius-5px {
  border-radius: 5px !important;
}

.radius-6px {
  border-radius: 6px !important;
}

.radius-8px {
  border-radius: 8px !important;
}

.radius-10px {
  border-radius: 10px !important;
}

.radius-12px {
  border-radius: 12px !important;
}

.radius-right-12px {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.radius-left-12px {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}

.radius-bottom-12px {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.radius-top-12px {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.radius-15px {
  border-radius: 15px !important;
}

.radius-16px {
  border-radius: 16px !important;
}

.radius-25px {
  border-radius: 25px !important;
}

.radius-26px {
  border-radius: 26px !important;
}

.radius-30px {
  border-radius: 30px !important;
}

.radius-32px {
  border-radius: 32px !important;
}

.radius-100 {
  border-radius: 100% !important;
}

.radius-100px {
  border-radius: 100px !important;
}

.no-text-wrap {
  white-space: nowrap !important;
}

.rotate-25 {
  transform: rotate(25deg);
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.scaleY-0 {
  transform: scaleY(0);
}

.scaleY-1 {
  transform: scaleY(1);
}

.text-decoration-none {
  text-decoration: none !important;
}

/* public end*/

.no-pointer-event {
  pointer-events: none !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.fit-height {
  height: fit-content !important;
}

.absolute-x-center {
  position: absolute !important;
  left: 50%;
  transform: translateX(-50%);
}

.absolute-y-center {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
}

.translateY-center {
  transform: translateX(-50%);
}

.absolute-center {
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.min-w-100 {
  min-width: 100% !important;
}

.min-w-150px {
  min-width: 150px !important;
}

.min-w-200px {
  min-width: 200px !important;
}

.fit-min-width {
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.fit-width {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-auto {
  left: auto;
}

.right-auto {
  right: auto;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-9 {
  z-index: 9 !important;
}

.z-10 {
  z-index: 10 !important;
}

.z-20 {
  z-index: 20 !important;
}

.z-99999 {
  z-index: 99999 !important;
}

.h-14px {
  min-height: 14px !important;
}

.h-17px {
  min-height: 17px !important;
  height: 17px !important;
}

.h-29px {
  min-height: 29px !important;
  height: 29px !important;
}

.h-32px {
  min-height: 32px !important;
}

.h-36px {
  height: 36px !important;
}

.h-70px {
  height: 70px !important;
}

.h-38px {
  height: 38px !important;
  min-height: 38px !important;
}

.h-40px {
  min-height: 40px !important;
}

.h-42px {
  min-height: 42px !important;
}

.h-44px {
  min-height: 44px !important;
}

.h-46px {
  min-height: 46px !important;
}

.h-52px {
  min-height: 52px !important;
}

.h-54px {
  min-height: 54px !important;
}

.h-55px {
  min-height: 55px !important;
}

.h-57px {
  min-height: 57px !important;
}

.h-60px {
  min-height: 60px !important;
}

.h-64px {
  min-height: 64px !important;
}

.h-66px {
  min-height: 66px !important;
}

.h-68px {
  min-height: 68px !important;
}

.h-80px {
  min-height: 80px !important;
}

.h-100px {
  min-height: 100px !important;
}

.h-122px {
  height: 122px !important;
}

.h-150px {
  height: 150px !important;
}

.h-280px {
  height: 280px !important;
}

.letter-spacing-5 {
  letter-spacing: 5px !important;
}

.letter-spacing-2 {
  letter-spacing: 2px !important;
}

.letter-spacing-1 {
  letter-spacing: 0.5px !important;
}

input {
  font-size: 16px !important;
  vertical-align: middle;
}

.vertical-align-baseline {
  vertical-align: baseline !important;
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.m-10px {
  margin: 10px !important;
}

.mt-10px,
.my-10px {
  margin-top: 10px !important;
}

.mb-10px,
.my-10px {
  margin-bottom: 10px !important;
}

.mb-2px,
.my-2px {
  margin-bottom: 2px !important;
}

.mt-6px,
.my-6px {
  margin-top: 6px !important;
}

.mr-6px,
.mx-6px {
  margin-right: 6px !important;
}

.ml-6px,
.mx-6px {
  margin-left: 6px !important;
}

.mr-10px,
.mx-10px {
  margin-right: 10px !important;
}

.ml-10px,
.mx-10px {
  margin-left: 10px !important;
}

.mr-12px,
.mx-12px {
  margin-right: 12px !important;
}

.ml-12px,
.mx-12px {
  margin-left: 12px !important;
}

.mt-12px,
.my-12px {
  margin-top: 12px !important;
}

.mt-14px,
.my-14px {
  margin-top: 14px !important;
}

.mt-16px,
.my-16px {
  margin-top: 16px !important;
}

.ml-20px,
.mx-20px {
  margin-left: 20px !important;
}

.mr-20px,
.mx-20px {
  margin-right: 20px !important;
}

.mb-20px,
.my-20px {
  margin-bottom: 20px !important;
}

.mt-20px,
.my-20px {
  margin-top: 20px !important;
}

.ml-2px,
.mx-2px {
  margin-left: 2px !important;
}

.mr-2px,
.mx-2px {
  margin-right: 2px !important;
}

.mb-2px,
.my-2px {
  margin-bottom: 2px !important;
}

.mt-2px,
.my-2px {
  margin-top: 2px !important;
}

.mb-8px,
.my-8px {
  margin-bottom: 8px !important;
}

.mt-8px,
.my-8px {
  margin-top: 8px !important;
}

.ml--20px,
.mx--20px {
  margin-left: -20px !important;
}

.mr--20px,
.mx--20px {
  margin-right: -20px !important;
}

.mb--20px,
.my--20px {
  margin-bottom: -20px !important;
}

.mt--20px,
.my--20px {
  margin-top: -20px !important;
}

.mt-24px,
.my-24px {
  margin-top: 24px !important;
}

.mt-30px,
.my-30px {
  margin-top: 30px !important;
}

.mb-30px,
.my-30px {
  margin-bottom: 30px !important;
}

.mt-40px,
.my-40px {
  margin-top: 40px !important;
}

.mb-40px,
.my-40px {
  margin-bottom: 40px !important;
}

.mt-50px,
.my-50px {
  margin-top: 50px !important;
}

.mt-60px,
.my-60px {
  margin-top: 60px !important;
}

.mb-60px,
.my-60px {
  margin-bottom: 60px !important;
}

.mt-90px,
.my-90px {
  margin-top: 90px !important;
}

.mt-120px,
.my-120px {
  margin-top: 120px !important;
}

.pt-30px,
.py-30px,
.p-30px {
  padding-top: 30px !important;
}

.pb-30px,
.py-30px,
.p-30px {
  padding-bottom: 30px !important;
}

.pl-30px,
.px-30px,
.p-30px {
  padding-left: 30px !important;
}

.pr-30px,
.px-30px,
.p-30px {
  padding-right: 30px !important;
}

.pt-20px,
.py-20px,
.p-20px {
  padding-top: 20px !important;
}

.pb-20px,
.py-20px,
.p-20px {
  padding-bottom: 20px !important;
}

.pl-20px,
.px-20px,
.p-20px {
  padding-left: 20px !important;
}

.pr-20px,
.px-20px,
.p-20px {
  padding-right: 20px !important;
}

.p-25px {
  padding: 25px !important;
}

.py-25px {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.p-20px {
  padding: 20px !important;
}

.pr-24px,
.px-24px,
.p-24px {
  padding-right: 24px !important;
}

.pl-24px,
.px-24px,
.p-24px {
  padding-left: 24px !important;
}

.pt-24px,
.py-24px,
.p-24px {
  padding-top: 24px !important;
}

.pb-24px,
.py-24px,
.p-24px {
  padding-bottom: 24px !important;
}

.p-18px {
  padding: 18px !important;
}

.px-12px {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.pr-10px,
.px-10px {
  padding-right: 10px !important;
}

.px-20px,
.p-20px {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px-10px,
.p-10px {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.py-10px,
.p-10px {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py-14px,
.p-14px {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.px-14px,
.p-14px {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.py-20px,
.p-20px {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py-12px,
.p-12px {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.px-12px,
.p-12px {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.py-6px,
.p-6px {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.px-6px,
.p-6px {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.py-4px,
.p-4px {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.px-4px,
.p-4px {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

@media (max-width: 350px) {
  .font-xs-10 {
    font-size: 10px !important;
  }
}

@media (max-width: 1199px) {
  .hidden-b-xl {
    display: none;
  }

  .show-b-xl {
    display: block !important;
  }
}

@media (min-width: 1600px) {
  body .justify-content-xxl-around {
    justify-content: space-around !important;
  }
}

@media (max-width: 1020px) {
  .hidden-1020 {
    display: none !important;
  }
}

@media (min-width: 1021px) {
  .show-1020 {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .pt-xl-20px,
  .py-xl-20px,
  .p-xl-20px {
    padding-top: 20px !important;
  }

  .pb-xl-20px,
  .py-xl-20px,
  .p-xl-20px {
    padding-bottom: 20px !important;
  }

  .pl-xl-20px,
  .px-xl-20px,
  .p-xl-20px {
    padding-left: 20px !important;
  }

  .pr-xl-20px,
  .px-xl-20px,
  .p-xl-20px {
    padding-right: 20px !important;
  }

  .pl-xl-30px,
  .px-xl-30px,
  .p-xl-30px {
    padding-left: 40px !important;
  }

  .pr-xl-30px,
  .px-xl-30px,
  .p-xl-30px {
    padding-right: 30px !important;
  }

  .pt-xl-90px,
  .py-xl-90px,
  .p-xl-90px {
    padding-top: 90px !important;
  }

  .pb-xl-90px,
  .py-xl-90px,
  .p-xl-90px {
    padding-bottom: 90px !important;
  }

  .pl-xl-90px,
  .px-xl-90px,
  .p-xl-90px {
    padding-left: 90px !important;
  }

  .pr-xl-90px,
  .px-xl-90px,
  .p-xl-90px {
    padding-right: 90px !important;
  }
}

@media (min-width: 992px) {
  .border-top-lg-1px {
    border-top: 1px solid !important;
  }

  .py-lg-30px,
  .p-lg-30px {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .overflow-lg-auto {
    overflow: auto !important;
  }

  .overflow-lg-hidden {
    overflow: hidden !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .mt-lg-50px,
  .my-lg-50px,
  .m-lg-50px {
    margin-top: 50px !important;
  }

  .mb-lg-50px,
  .my-lg-50px,
  .m-lg-50px {
    margin-bottom: 50px !important;
  }

  .ml-lg-50px,
  .mx-lg-50px,
  .m-lg-50px {
    margin-left: 50px !important;
  }

  .mr-lg-50px,
  .mx-lg-50px,
  .m-lg-50px {
    margin-right: 50px !important;
  }

  .pt-lg-30px,
  .py-lg-30px,
  .p-lg-30px {
    padding-top: 30px !important;
  }

  .pb-lg-30px,
  .py-lg-30px,
  .p-lg-30px {
    padding-bottom: 30px !important;
  }

  .pl-lg-30px,
  .px-lg-30px,
  .p-lg-30px {
    padding-left: 30px !important;
  }

  .pr-lg-30px,
  .px-lg-30px,
  .p-lg-30px {
    padding-right: 30px !important;
  }

  .pt-lg-20px,
  .py-lg-20px,
  .p-lg-20px {
    padding-top: 20px !important;
  }

  .pb-lg-20px,
  .py-lg-20px,
  .p-lg-20px {
    padding-bottom: 20px !important;
  }

  .pl-lg-20px,
  .px-lg-20px,
  .p-lg-20px {
    padding-left: 20px !important;
  }

  .pr-lg-20px,
  .px-lg-20px,
  .p-lg-20px {
    padding-right: 20px !important;
  }

  .pt-lg-60px,
  .py-lg-60px,
  .p-lg-60px {
    padding-top: 60px !important;
  }

  .pb-lg-60px,
  .py-lg-60px,
  .p-lg-60px {
    padding-bottom: 60px !important;
  }

  .pl-lg-60px,
  .px-lg-60px,
  .p-lg-60px {
    padding-left: 60px !important;
  }

  .pr-lg-60px,
  .px-lg-60px,
  .p-lg-60px {
    padding-right: 60px !important;
  }

  .pt-lg-90px,
  .py-lg-90px,
  .p-lg-90px {
    padding-top: 90px !important;
  }

  .pb-lg-90px,
  .py-lg-90px,
  .p-lg-90px {
    padding-bottom: 90px !important;
  }

  .pl-lg-90px,
  .px-lg-90px,
  .p-lg-90px {
    padding-left: 90px !important;
  }

  .pr-lg-90px,
  .px-lg-90px,
  .p-lg-90px {
    padding-right: 90px !important;
  }
}

@media (min-width: 768px) {
  .mt-md-60px,
  .my-md-60px {
    margin-top: 60px !important;
  }

  .mt-md-150px,
  .my-md-150px {
    margin-top: 150px !important;
  }

  .mb-md-56px,
  .my-md-56px {
    margin-bottom: 56px !important;
  }

  .mt-md-50px,
  .my-md-50px {
    margin-top: 50px !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .w-md-auto {
    width: auto !important;
  }
}

@media (min-width: 500px) {
  .w-sm-auto {
    width: auto !important;
  }
}

.hide-scrollbar {
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}
